import { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { Track } from '@/components/audioPlayer/WaveForm';

export interface MediaPlayerContextType {
  // State
  tracks: Track[];
  currentTrackIndex: number;
  isPlaying: boolean;
  duration: number;
  currentTime: number;
  isLoading: boolean;
  isError: boolean;

  // Actions
  setTracks: (tracks: Track[]) => void;
  playTrack: (index: number) => void;
  pauseTrack: () => void;
  nextTrack: () => void;
  previousTrack: () => void;
  setDuration: (duration: number) => void;
  setCurrentTime: (time: number) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsError: (isError: boolean) => void;
}

export const MediaPlayerContext = createContext<MediaPlayerContextType | undefined>(undefined);

interface MediaPlayerProviderProps {
  children: ReactNode;
}

export function MediaPlayerProvider({ children }: MediaPlayerProviderProps) {
  const [tracks, setTracks] = useState<Track[]>([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const playTrack = useCallback(
    (index: number) => {
      if (index === currentTrackIndex) {
        setIsPlaying(true);
        return;
      }

      if (index >= 0 && index < tracks.length) {
        setCurrentTrackIndex(index);
        setIsPlaying(true);
        setIsError(false);
      }
    },
    [currentTrackIndex, tracks.length]
  );

  const pauseTrack = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const nextTrack = useCallback(() => {
    if (currentTrackIndex < tracks.length - 1) {
      setCurrentTrackIndex((prev) => prev + 1);
      setIsError(false);
    } else {
      setIsPlaying(false);
    }
  }, [currentTrackIndex, tracks.length]);

  const previousTrack = useCallback(() => {
    if (currentTrackIndex > 0) {
      setCurrentTrackIndex((prev) => prev - 1);
      setIsError(false);
    }
  }, [currentTrackIndex]);

  const value = {
    // State
    tracks,
    currentTrackIndex,
    isPlaying,
    duration,
    currentTime,
    isLoading,
    isError,

    // Actions
    setTracks,
    playTrack,
    pauseTrack,
    nextTrack,
    previousTrack,
    setDuration,
    setCurrentTime,
    setIsLoading,
    setIsError
  };

  return <MediaPlayerContext.Provider value={value}>{children}</MediaPlayerContext.Provider>;
}

export function useMediaPlayer() {
  const context = useContext(MediaPlayerContext);
  if (context === undefined) {
    throw new Error('useMediaPlayer must be used within a MediaPlayerProvider');
  }
  return context;
}
