import React, { useEffect, useRef, useState } from 'react';
import { Toast as ToastInterface } from '@/@types/Toast';
import { Transition } from '@headlessui/react';
import { Notification } from '../notifications/Notification';

export function Toast({
  toast,
  removeToast
}: {
  toast: ToastInterface;
  removeToast: (id: string) => void;
}) {
  const mountedTimeout = useRef<NodeJS.Timeout>();
  const visibleTimeout = useRef<NodeJS.Timeout>();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      setVisible(true);
    });
  }, []);

  useEffect(() => {
    if (!isVisible) {
      visibleTimeout.current = setTimeout(() => {
        removeToast(toast.id);
      }, 1000);
    }

    return () => {
      if (visibleTimeout.current) {
        clearTimeout(visibleTimeout.current);
      }
    };
  }, [isVisible, toast, removeToast]);

  useEffect(() => {
    if (!toast.timeout) {
      return;
    }

    mountedTimeout.current = setTimeout(() => {
      setVisible(false);
    }, toast.timeout);

    return () => {
      if (mountedTimeout.current) {
        clearTimeout(mountedTimeout.current);
      }
    };
  }, [toast]);

  return (
    <div>
      <Transition
        show={isVisible}
        enter="transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Notification
          message={toast.message}
          title={toast.title}
          type={toast.type}
          icon={toast.icon}
          isLoading={toast.isLoading}
          onClear={(visible: boolean) => setVisible(visible)}
          buttonLink={toast.buttonLink}
          buttonLinkIsExternal={toast.buttonLinkIsExternal}
          buttonLabel={toast.buttonLabel}
        />
      </Transition>
    </div>
  );
}
