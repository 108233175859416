import Pusher from 'pusher-js';
import Echo, { Channel } from 'laravel-echo';
import { useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import { useAuth } from '@/hooks/useAuth';
import { SharedPageProps } from '@/@types/InertiaPage';

export function useWebsocket(
  channel: string,
  event: string,
  callback: (data: any) => void,
  isPrivate: boolean = true
) {
  const pusherAppKey = import.meta.env.VITE_PUSHER_APP_KEY;
  const pusherAppCluster = import.meta.env.VITE_PUSHER_APP_CLUSTER;

  if (!window.Pusher) {
    window.Pusher = Pusher;
  }

  if (!window.Echo && pusherAppKey && pusherAppCluster) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      forceTLS: true
    });
  }

  useEffect(
    () => {
      if (!window.Echo) {
        return;
      }

      const echoChannel: Channel = isPrivate
        ? window.Echo.private(channel)
        : window.Echo.channel(channel);

      if (!echoChannel) {
        return;
      }

      echoChannel.listen(event, callback);

      return () => {
        echoChannel.stopListening(event);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [event, channel, isPrivate]
  );
}

export function useUserWebsocket(event: string, callback: (data: any) => void) {
  const { userId } = useAuth();
  return useWebsocket(`user.${userId}`, event, callback);
}

export function useSessionWebsocket(event: string, callback: (data: any) => void) {
  const { props } = usePage<SharedPageProps>();
  return useWebsocket(`session.${props.session.id}`, event, callback, false);
}
