import { useSessionWebsocket } from '@/hooks/useWebsocket';
import { uuid } from '@/utils/UuidUtil';
import useToasts from '@/hooks/useToasts';

export function useDownloadReadyToast() {
  const { addToast, removeToast } = useToasts();

  useSessionWebsocket('.download-ready', (data) => {
    removeToast(data.id);

    addToast({
      id: uuid(),
      icon: true,
      type: 'success',
      title: 'Download Ready',
      message: `${data.filename} is ready for download.`,
      buttonLabel: 'Download',
      buttonLinkIsExternal: true,
      buttonLink: data.url,
      isLoading: false
    });
  });
}
