import { RmsOption, RmsOptionWithArtistAgreement } from '@/@types/RmsOption';
import { ProductDelivery } from '@/@types/ProductDelivery';
import { ArtistAgreementOption } from '@/@types/AgreementOption';
import { Product } from '@/@types/Product';
import { RmsAgreement } from '@/@types/RmsAgreement';
import { Agreement } from '@/@types/Agreement';

export function buildRmsRedirectUrl(targetUrl: string) {
  return `/rms/redirect?url=${encodeURIComponent(targetUrl)}`;
}

export function getReportsRmsRedirectUrl(urlParamsString: string, rmsBaseUrl: string): string {
  return buildRmsRedirectUrl(`${rmsBaseUrl}/contract_reports/report/report.php${urlParamsString}`);
}

export function getContractRmsRedirectUrl(
  rmsOptionOrAgreement: RmsOption | RmsAgreement,
  rmsBaseUrl: string
) {
  return buildRmsRedirectUrl(
    `${rmsBaseUrl}/contract_reports/master_rights_contract_list.php?contract_id=${rmsOptionOrAgreement.agreement_id}`
  );
}

export function getPublishingAgreementRmsRedirectUrl(
  agreement: Agreement,
  rmsBaseUrl: string
): string {
  return agreement.rms_agreement_id
    ? buildRmsRedirectUrl(
        `${rmsBaseUrl}/contract_reports/publishing_contract.php?contract_id=${agreement.rms_agreement_id}`
      )
    : '';
}

export function getDeliveryRmsRedirectUrl(productDelivery: ProductDelivery, rmsBaseUrl: string) {
  return buildRmsRedirectUrl(
    `${rmsBaseUrl}/contract_system/deliveries/view_delivery.php?delivery_id=${productDelivery.delivery_id}`
  );
}

export function getScheduleRmsRedirectUrl(product: Product, rmsBaseUrl: string) {
  return buildRmsRedirectUrl(
    `${rmsBaseUrl}/contract_reports/schedule/?page=view_product&product_id=${product.rms_schedule_id}`
  );
}

export function artistAgreementOptionsToRmsOptionsWithArtistAgreements(
  options?: ArtistAgreementOption[]
): RmsOptionWithArtistAgreement[] {
  if (!options) {
    return [];
  }

  return options
    .filter((option) => !!option.rms_option)
    .map((option) => {
      return {
        ...option.rms_option,
        agreement: { contractable: { name: option.agreement.contractable.name } }
      };
    }) as RmsOptionWithArtistAgreement[];
}
